<template>
    <vs-popup class="memberAdde_popup" title="Ajout" :active.sync="popupActive">
        <template v-if="loading==false">
        <div class="vx-row mb-6 tocenter" >
            <div class="vx-col">
                <vs-radio v-model="type" vs-name="radios1" vs-value="u">Employé</vs-radio>
            </div>
            <div  class="vx-col">
                <vs-radio v-model="type" vs-name="radios1" vs-value="c">Comptable</vs-radio>
            </div>
            <div  class="vx-col">
                    <vs-radio v-model="type" vs-name="radios1" vs-value="a">Administrateur</vs-radio>
            </div>

        </div>
           <br>

            <div class="vx-row w-full">
                <div class="vx-col mb-6">
                    <vs-input label="Prénom" v-model="first_name" />
                </div>
                <div class="vx-col mb-6">
                    <vs-input  label="Nom" v-model="last_name" />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                <vue-tel-input class="w-full" v-model="phone"></vue-tel-input>
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                <vs-input class="w-full" label="Email (facultatif)" v-model="email" />
                </div>
            </div>

            <span class="red">Le login doit comporter deux mots avec un espace exemple "prenom nom" et comporter plus de 5 caractères.</span><br/>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                <vs-input class="w-full" label="login" v-model="login" />
                </div>
            </div>


            <span class="red">Le mot de passe doit contenir plus de 8 caractères ainsi que des nombres et un @.<br/>
            Exemple de mot de passe: "nomprenom@1990"</span><br/>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                <vs-input class="w-full" label="Mot de passe" type="password" v-model="password" />
                </div>
            </div>

            <vs-button color="success" type="filled" v-on:click="add()" class="w-full btn_channel">Ajouter</vs-button>

        </template>
        <template v-else>
            <h2 class="text-center center">Création du membre en cours ...</h2>
        </template>
    </vs-popup>
</template>

<style lang="scss">
.memberAdde_popup
{
    .red{
        color:red;
    }
}

.tocenter{
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

<script>

import Member  from '@/database/models/member'
import vSelect from 'vue-select'

export default {
    components:{
        vSelect
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            callback:null,
            loading: false,

            first_name: '',
            last_name : '',
            phone     : '',
            email     : '',
            login     : '',
            password  : '',
            type      : 'u',

        }
    },
    watch:{
        $route (){
        },
    },
    beforeMount:function(){
    },
    methods:{
        openPopup( callback )
        {
            this.first_name = ''
            this.last_name  = ''
            this.phone      = ''
            this.email      = ''
            this.login      = ''
            this.password   = ''
            this.type       = 'u'
            this.callback    = null
            this.popupActive = true
            this.loading     = false

            if(callback)
                this.callback = callback
        },
        add()
        {
            this.loading = true

            Member.add(
            {
                first_name : this.first_name,
                last_name  : this.last_name,
                phone      : this.phone.split(' ').join(''),
                email      : this.email,
                active     : 1,
                type       : this.type,
                login      : this.login,
                password   : this.password
            })
            .then((uid)=>
            {
                //fermeture popup
                this.popupActive = false

                //callback event
                if(this.callback)
                    this.callback(uid)

                //Event refresh
                this.$emit('refresh')
            })
            .catch(()=>
            {
                this.popupActive = false;
            });
        }
    }
}

</script>